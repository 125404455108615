import React, { PropsWithChildren, useState } from 'react';

import { Navigation } from '../features/navigation';
import { Header } from '../shared/components/Header';

export const RootLayout = ({ children }: PropsWithChildren<object>) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsMenuVisible((prev) => !prev);
  };

  const closeMenu = () => {
    setIsMenuVisible(false);
  };

  return (
    <>
      <Header onToggleMenu={toggleMenu} isMenuVisible={isMenuVisible} />
      <Navigation visible={isMenuVisible} closeMenu={closeMenu} />
      {children}
    </>
  );
};
