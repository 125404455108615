import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthUser } from 'app/providers/AuthUserProvider';

import { ROUTES } from 'shared/constants/routes';

export const NotFound = () => {
  const {
    models: { authenticated },
  } = useAuthUser();

  const url = authenticated ? ROUTES.HOME : ROUTES.LOG_IN;

  return <Navigate to={url} replace />;
};
