import React from 'react';
import styled from 'styled-components';

/**
 * Stack component props
 */
export interface StackProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Defines flex items alignment
   * @default 'center'
   */

  alignItems?: React.CSSProperties['alignItems'];
  /**
   * Defines flex direction
   * @default 'column'
   */
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  /**
   * Gap between children in pixels
   * @default 16
   */
  gap?: number;
  /**
   * Defines flex alignment along the main axis
   * @default 'center'
   */

  justifyContent?: React.CSSProperties['justifyContent'];
}

interface StyledStackProps {
  $alignItems: Required<StackProps>['alignItems'];
  $direction: Required<StackProps>['direction'];
  $gap: Required<StackProps>['gap'];
  $justifyContent: Required<StackProps>['justifyContent'];
}

const MARGIN_MAP = {
  row: 'margin-left',
  'row-reverse': 'margin-right',
  column: 'margin-top',
  'column-reverse': 'margin-bottom',
};

const StyledStack = styled.div<StyledStackProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  align-items: ${({ $alignItems }) => $alignItems};
  justify-content: ${({ $justifyContent }) => $justifyContent};

  > :not(style) + :not(style) {
    ${({ $direction, $gap }) => `${MARGIN_MAP[$direction]}: ${$gap}px`}
  }

  width: 100%;
`;

/**
 * Renders Stack component. The Stack component manages layout of immediate children
 * along the vertical or horizontal axis with optional spacing between each child.
 */
const Stack = React.forwardRef<HTMLDivElement, StackProps>(
  (
    {
      justifyContent = 'center',
      alignItems = 'center',
      direction = 'column',
      gap = 16,
      ...rest
    }: StackProps,
    ref
  ) => {
    return (
      <StyledStack
        ref={ref}
        $justifyContent={justifyContent}
        $alignItems={alignItems}
        $direction={direction}
        $gap={gap}
        {...rest}
      />
    );
  }
);

export { Stack };
