export const enum LocaleSource {
  PATH = 'path',
  QUERY = 'query',
  LOCAL_STORAGE = 'localStorage',
  COOKIE = 'cookie',
  BROWSER = 'browser',
}

export const enum CacheType {
  LOCAL_STORAGE = 'localStorage',
  COOKIE = 'cookie',
}
