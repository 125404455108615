export const STYLES = {
  variants: {
    primary: {
      sizes: {
        small: {
          'font-size': '14px',
          'line-height': '20px',
          'font-weight': '700',
          'min-width': '140px',
          'min-height': '44px',
        },
        medium: {
          'font-size': '17px',
          'line-height': '26px',
          'font-weight': '700',
          width: '100%',
          'max-width': '358px',
          height: '64px',
          'letter-spacing': '2px',
        },
      },
    },
    plain: {
      sizes: {
        small: {
          'font-size': '14px',
          'line-height': '20px',
          'font-weight': '400',
          padding: '2px 8px;',
        },
        medium: {
          'font-size': '16px',
          'line-height': '28px',
          'font-weight': '600',
          padding: '2px 8px;',
        },
      },
    },
  },
};
