import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { StrictMode, FC, PropsWithChildren } from 'react';

import { AuthTokenProvider } from './AuthTokenProvider';
import { AuthUserProvider } from './AuthUserProvider';
import { LocalizationProvider } from './LocalizationProvider';
import { ThemeProvider } from './ThemeProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

export const withProviders = <T,>(Component: FC<PropsWithChildren<T>>) => {
  return (props: PropsWithChildren<T>) => (
    <StrictMode>
      <ThemeProvider defaultTheme="default">
        <QueryClientProvider client={queryClient}>
          <AuthTokenProvider>
            <AuthUserProvider>
              <LocalizationProvider>
                <Component {...props} />
              </LocalizationProvider>
            </AuthUserProvider>
          </AuthTokenProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </StrictMode>
  );
};
