import React, { ReactNode } from 'react';

import { Stack } from 'ui-elements/Stack';
import { Typography } from 'ui-elements/Typography';

export const Row = ({ leftText, rightText }: { leftText: ReactNode; rightText: ReactNode }) => {
  return (
    <Stack direction="row" justifyContent="space-between" gap={8}>
      <Typography variant="heading3" weight="normal" color="text-secondary">
        {leftText}
      </Typography>
      <Typography variant="heading3">{rightText}</Typography>
    </Stack>
  );
};
