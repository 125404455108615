import React from 'react';

import { StepContent } from 'shared/components/StepContent';
import { StepLayout } from 'shared/components/StepLayout';

import { Button } from 'ui-elements/Button';
import { FormItem } from 'ui-elements/FormItem';
import { InputSkeleton, TextSkeleton } from 'ui-elements/Skeleton';
import { Typography } from 'ui-elements/Typography';

export const ChangePasswordSkeleton = () => {
  return (
    <StepLayout className="!pt-10">
      <StepContent>
        <Typography variant="heading2" align="center" display="block" gutterBottom={48}>
          <TextSkeleton width="170px" />
        </Typography>

        <FormItem label={<TextSkeleton width="100px" />}>
          <InputSkeleton className="mb-9" />
        </FormItem>

        <FormItem label={<TextSkeleton width="100px" />}>
          <InputSkeleton className="mb-9" />
        </FormItem>

        <FormItem label={<TextSkeleton width="100px" />}>
          <InputSkeleton className="mb-9" />
        </FormItem>

        <Button disabled>
          <TextSkeleton width="120px" />
        </Button>
      </StepContent>
    </StepLayout>
  );
};
