import styled from 'styled-components';

import { BaseButton } from 'ui-elements/BaseButton';
import { Spinner } from 'ui-elements/Spinner';

import { STYLES } from './styles';
import { StyledButtonProps } from './types';

export const StyledRegularButton = styled(BaseButton)<StyledButtonProps>`
  position: relative;
  display: block;
  ${({ $size, $variant }) => STYLES.variants[$variant].sizes[$size]};
  color: ${({ theme, $color }) => theme.color($color ?? 'text-on-primary')};
  background-color: ${({ $loading, theme }) =>
    $loading ? theme.color('action-primary-active') : theme.color('action-primary-default')};
  border-radius: 12px;
  margin: 0 auto;

  pointer-events: ${({ $loading }) => ($loading ? 'none' : 'all')};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ theme }) => theme.color('action-primary-hover')};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.color('action-primary-active')};
  }

  &:disabled {
    color: ${({ theme }) => theme.color('text-disabled')};
    background-color: ${({ theme }) => theme.color('action-primary-disabled')};
  }
`;

export const StyledPlainButton = styled(BaseButton)<StyledButtonProps>`
  display: block;
  ${({ $size, $variant }) => STYLES.variants[$variant].sizes[$size]};
  color: ${({ theme, $color }) => theme.color($color ?? 'action-primary-default')};

  pointer-events: all;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme }) => theme.color('action-primary-hover')};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.color('text-disabled')};
  }
`;

export const StyledContent = styled.span<{ $loading: boolean; $uppercase: boolean }>`
  position: relative;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;

  text-transform: ${({ $uppercase }) => ($uppercase ? 'uppercase' : 'none')};
  visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
