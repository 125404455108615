import axios from 'axios';
import React from 'react';
import { IntlProvider } from 'react-intl';

import { useLoadJsonFile } from 'shared/hooks/useLoadJsonFile';

import { logger } from 'shared/services/logger';

import { AVAILABLE_LOCALES, DEFAULT_LOCALE } from './constants';
import { CacheType, LocaleDetector, LocaleSource } from './helpers/LocaleDetector';

const localeDetector = new LocaleDetector({
  order: [
    LocaleSource.PATH,
    LocaleSource.QUERY,
    LocaleSource.LOCAL_STORAGE,
    LocaleSource.COOKIE,
    LocaleSource.BROWSER,
  ],
  lookup: {
    query: 'locale',
    cookie: 'frr_locale',
    localStorage: 'frr:locale',
  },
  caches: [CacheType.LOCAL_STORAGE, CacheType.COOKIE],
});

interface LocalizationContextInterface {
  models: {
    isLoading: boolean;
    isReady: boolean;
  };
  operations: {
    /**
     * Method to change current locale
     */
    changeLocale: (locale: string) => void;
  };
}

/**
 * Contains theme api
 */
const LocalizationContext = React.createContext<LocalizationContextInterface | null>(null);

/**
 * Provides theme api to child components via hooks api.
 */
function useLocalization() {
  const context = React.useContext(LocalizationContext);

  if (!context) {
    throw new Error(`useLocalization must be used within LocalizationProvider`);
  }

  return context;
}

interface LocalizationProviderProps {
  /**
   * The child nodes LocalizationProvider has wrapped
   */
  children?: React.ReactNode;
}

/**
 * Provides theme api to its child components via context api.
 */
const LocalizationProvider = ({ children }: LocalizationProviderProps): JSX.Element => {
  const [locale, setLocale] = React.useState('');
  const {
    data: messages,
    isSuccess,
    isLoading,
  } = useLoadJsonFile<Record<string, string>>(`/locales/${locale}.json`, {
    enabled: Boolean(locale) && locale !== 'en',
    retryStrategy: 'exponentialDelay',
    useErrorBoundary: true,
    retryCondition: (error) => axios.isAxiosError(error),
    onError: (error) => {
      logger.error(Error('App.start.error', { cause: error }), {
        tags: {
          'app.feature': 'core',
          'app.component': 'localization',
        },
      });
    },
  });

  React.useEffect(() => {
    const lookedLocale = localeDetector.lookup(AVAILABLE_LOCALES) || '';
    const localeValue = AVAILABLE_LOCALES.includes(lookedLocale) ? lookedLocale : DEFAULT_LOCALE;

    // set lang attribute
    document.documentElement.setAttribute('lang', localeValue);

    setLocale(localeValue);
  }, []);

  React.useEffect(() => {
    if (locale) {
      // update lang attribute
      document.documentElement.setAttribute('lang', locale);

      // cache locale
      localeDetector.cacheLocale(locale);
    }
  }, [locale]);

  // provide localization api to components
  const api = React.useMemo<LocalizationContextInterface>(
    () => ({
      models: {
        isLoading,
        isReady: isSuccess || locale === 'en',
      },
      operations: {
        changeLocale: (newLocale: string) => {
          if (!AVAILABLE_LOCALES.includes(newLocale)) {
            logger.warn('Unsupported locale: ', newLocale);

            return;
          }

          setLocale(newLocale);
        },
      },
    }),
    [isSuccess, isLoading, locale]
  );

  return (
    <LocalizationContext.Provider value={api}>
      {locale && (
        <IntlProvider
          messages={locale === 'en' ? undefined : messages}
          locale={locale}
          defaultLocale={DEFAULT_LOCALE}
        >
          {api.models.isReady && children}
        </IntlProvider>
      )}
    </LocalizationContext.Provider>
  );
};

export { LocalizationProvider, useLocalization };
