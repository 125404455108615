export const imageConfigToProps = <C extends ImageConfig>({
  config,
  fallbackImageUrl,
  fallbackImageSize,
  ...rest
}: C) => ({
  sources: config,
  src: fallbackImageUrl,
  width: fallbackImageSize?.width,
  height: fallbackImageSize?.height,
  ...rest,
});
