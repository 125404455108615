import * as appLogo from 'pages/install-app/images/app-logo';

import { commonMessages } from 'shared/intl/common.intl';
import { imageConfigToProps } from 'shared/utils/imageConfigToProps';

import { messages } from './AccountSubscriptions.intl';

export const config = {
  texts: {
    title: messages.title,
    expiryDate: messages.expiryDate,
    expiredDate: messages.expiredDate,
    billingPeriod: messages.billingPeriod,
    nextChargeDate: messages.nextChargeDate,
    price: messages.price,
    cancelRenewal: messages.cancelRenewal,
    cancelledHintTitle: messages['subscriptions.cancelledHint.title'],
    cancelledHintMessage: messages['subscriptions.cancelledHint.message'],
    emptySubscription: messages['subscriptions.empty'],
    oops: commonMessages['common.error.message.oops'],
  },
  period: {
    week: messages['subscriptions.plan.weeks'],
    month: messages['subscriptions.plan.months'],
    year: messages['subscriptions.plan.year'],
  },
  billingPeriod: {
    week: messages['subscriptions.billing.weeks'],
    month: messages['subscriptions.billing.months'],
    year: messages['subscriptions.billing.year'],
  },
  images: {
    appLogo: imageConfigToProps(appLogo),
  },
};
