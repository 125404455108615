import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

import { useAuthToken } from 'app/providers/AuthTokenProvider';

import { getForerunnerApiKey, getForerunnerApiUrl } from 'shared/utils/env';

const axiosInstance = Axios.create({ baseURL: getForerunnerApiUrl() });

const DEFAULT_HEADERS = {
  'x-api-key': getForerunnerApiKey(),
};

export const useHttpClient = <ResponseData>() => {
  const { token } = useAuthToken();

  return useCallback(
    (config: AxiosRequestConfig<unknown>) => {
      config.headers = {
        ...DEFAULT_HEADERS,
        'x-finalize-token': token,
        token,
      };

      return axiosInstance({ ...config })
        .then(({ data, headers: { token: headersToken } }) => {
          if (headersToken) {
            data.token = headersToken;
          }

          return data as ResponseData;
        })
        .catch();
    },
    [token]
  );
};

export interface ErrorType<T = unknown> extends AxiosError<T> {
  error: string;
}
