import { withResolvers } from 'shared/utils/promise';

const UserReadyService = () => {
  let userReadyPromise = withResolvers();

  const resetUserReadyPromise = () => {
    userReadyPromise = withResolvers();
  };

  return {
    promise: userReadyPromise.promise,
    resolve: userReadyPromise.resolve,
    resetUserReadyPromise,
  };
};

export const userReadyService = UserReadyService();
