import cx from 'classnames';
import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Link } from 'react-router-dom';

type CustomProps = {
  href: string;
  title: MessageDescriptor;
  target?: AnchorHTMLAttributes<'a'>['target'];
  small?: boolean;
  isActive?: boolean;
};

type NavItemProps = Omit<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  keyof CustomProps | 'ref'
> &
  CustomProps;

export const NavItem = ({
  href,
  title,
  target = '_self',
  small,
  isActive,
  ...rest
}: NavItemProps) => {
  return (
    <li>
      <Link
        className={cx(
          'block rounded-xl px-4 py-4 text-text-primary no-underline',
          'hover:bg-action-primary-default hover:font-medium hover:text-text-on-primary',
          isActive && 'bg-action-primary-default font-medium !text-text-on-primary',
          small && 'text-sm'
        )}
        to={href}
        target={target}
        {...rest}
      >
        <FormattedMessage {...title} />
      </Link>
    </li>
  );
};
