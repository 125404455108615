import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthUser } from 'app/providers/AuthUserProvider';

import { ROUTES } from 'shared/constants/routes';

export const PrivateRoute = ({ children }: PropsWithChildren) => {
  const {
    models: { authenticated },
  } = useAuthUser();

  return authenticated ? children : <Navigate to={ROUTES.LOG_IN} />;
};
