const disallowedKeys = ['token', 'resetToken'];

type StringDictionary = Record<string, string>;

export const sanitizeObject = (parsedQueryParams: StringDictionary) => {
  return Object.keys(parsedQueryParams).reduce<StringDictionary>((acc, key) => {
    if (!disallowedKeys.includes(key)) {
      acc[key] = parsedQueryParams[key];
    }

    return acc;
  }, {});
};
