import { sanitizeObject } from './sanitizeObject';

import type { AnalyticUtils } from 'wikr-core-analytics';

type StringDictionary = Record<string, string>;

// Here we patch UtilsNew class from wikr-core-analytics to exclude sensitive query params & cookies from being sent to analytic services

export const patchAnalyticUtils = (analyticUtils: typeof AnalyticUtils): typeof AnalyticUtils => {
  analyticUtils.getUrlParams = () => {
    const urlParamsFromStore = localStorage.getItem('urlParams');

    const urlParams = urlParamsFromStore !== null && JSON.parse(urlParamsFromStore);
    const localUrlParams: StringDictionary = urlParams || analyticUtils.getDefaultUrlParams();

    const params: StringDictionary = {};

    new URLSearchParams(window.location.search).forEach((value, key) => {
      params[key] = value;
    });

    try {
      const queryParams = Object.keys(params).length ? params : localUrlParams;

      return sanitizeObject(queryParams);
    } catch (e) {
      return {
        data: 'empty_url',
        error: JSON.stringify(e),
      };
    }
  };

  analyticUtils.getCookies = () => {
    const reducer = (accumulator: StringDictionary, current: string) => {
      const [name, value] = current.split('=');
      accumulator[name] = value;

      return accumulator;
    };

    const cookiesAsObject = document.cookie.split('; ').reduce(reducer, {});

    return sanitizeObject(cookiesAsObject);
  };

  return analyticUtils;
};
