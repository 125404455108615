import React, { ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';

export interface ImageSource {
  media?: string;
  srcSet: string;
  type: string;
}
export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  sources: Array<ImageSource> | null;
  display?: 'initial' | 'inline' | 'block' | 'flex';
  imgClassName?: string;
}

interface StyledImageProps {
  $display: Required<ImageProps>['display'];
}

const StyledImage = styled.img<StyledImageProps>`
  ${({ $display }) => ($display !== 'initial' ? `display: ${$display};` : '')}
`;

const Image = forwardRef(
  (
    {
      className,
      imgClassName,
      sources,
      alt,
      loading = 'lazy',
      display = 'inline',
      src,
      srcSet,
      ...other
    }: ImageProps,
    pictureRef: ForwardedRef<HTMLPictureElement>
  ) => {
    /**
     * Workaround for https://github.com/facebook/react/issues/20682
     * To prevent loading images twice in Safari let's add src & srcSet attributes after img element creation
     */
    const refCallback = React.useCallback<React.RefCallback<HTMLImageElement>>(
      (node) => {
        if (!node) {
          return;
        }

        if (src) {
          node.src = src;
        }

        if (srcSet) {
          node.srcset = srcSet;
        }
      },
      [src, srcSet]
    );

    if (!sources) {
      return null;
    }

    return (
      <picture className={className} ref={pictureRef}>
        {sources.map(({ srcSet, ...rest }) => {
          return <source key={srcSet} srcSet={srcSet} {...rest} />;
        })}

        <StyledImage
          ref={refCallback}
          alt={alt}
          loading={loading}
          className={imgClassName}
          {...other}
          $display={display}
        />
      </picture>
    );
  }
);

export { Image };
