import { LOCALE_SOURCE_MAP, CACHE_TYPE_MAP } from './localeSourceMap';
import { LocaleDetectorOptions } from './types';

class LocaleDetector {
  options: LocaleDetectorOptions;

  constructor(options: LocaleDetectorOptions) {
    this.options = options;
  }

  lookup = (supportedLocales: Array<string>): string => {
    for (const source of this.options.order) {
      const locale = LOCALE_SOURCE_MAP[source](supportedLocales, this.options.lookup);

      if (locale) {
        return locale;
      }
    }

    return '';
  };

  cacheLocale = (locale: string) => {
    for (const cacheType of this.options.caches) {
      CACHE_TYPE_MAP[cacheType](locale, this.options.lookup);
    }
  };
}

export { LocaleDetector };
