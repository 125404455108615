import { CacheType, LocaleSource } from './constants';
import {
  cacheLocaleInCookie,
  cacheLocaleInLocalStorage,
  lookupLocaleFromBrowser,
  lookupLocaleFromCookie,
  lookupLocaleFromLocalStorage,
  lookupLocaleFromPath,
  lookupLocaleFromQuery,
} from './helpers';

export const LOCALE_SOURCE_MAP = {
  [LocaleSource.PATH]: lookupLocaleFromPath,
  [LocaleSource.QUERY]: lookupLocaleFromQuery,
  [LocaleSource.LOCAL_STORAGE]: lookupLocaleFromLocalStorage,
  [LocaleSource.COOKIE]: lookupLocaleFromCookie,
  [LocaleSource.BROWSER]: lookupLocaleFromBrowser,
};

export const CACHE_TYPE_MAP = {
  [CacheType.LOCAL_STORAGE]: cacheLocaleInLocalStorage,
  [CacheType.COOKIE]: cacheLocaleInCookie,
};
