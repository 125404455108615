import React, { InputHTMLAttributes, ReactNode } from 'react';

import { StyledInputLabel } from './styled';

export interface FormItemProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  children?: ReactNode;
}

export const FormItem = ({ label, children, ...rest }: FormItemProps) => {
  return (
    <div {...rest}>
      <StyledInputLabel variant="caption1" weight="medium">
        {label}
      </StyledInputLabel>
      {children}
    </div>
  );
};
