import { breakpoints } from './breakpoints';

type MediaSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';

type MediaQueryVariant = 'min' | 'only' | 'max';

type MediaSizeConfig = Record<MediaQueryVariant, string>;

/**
 * Media queries based on layout breakpoints in theme
 */
export const mediaQueries: Record<MediaSize, MediaSizeConfig> = {
  xxs: {
    min: `@media (min-width: 0px)`, // shouldn't be used
    only: `@media (max-width: ${breakpoints.xs - 1}px)`,
    max: `@media (max-width: ${breakpoints.xs - 1}px)`,
  },
  xs: {
    min: `@media (min-width: ${breakpoints.xs}px)`,
    only: `@media (min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.s - 1}px)`,
    max: `@media (max-width: ${breakpoints.s - 1}px)`,
  },
  s: {
    min: `@media (min-width: ${breakpoints.s}px)`,
    only: `@media (min-width: ${breakpoints.s}px) and (max-width: ${breakpoints.m - 1}px)`,
    max: `@media (max-width: ${breakpoints.m - 1}px)`,
  },
  m: {
    min: `@media (min-width: ${breakpoints.m}px)`,
    only: `@media (min-width: ${breakpoints.m}px) and (max-width: ${breakpoints.l - 1}px)`,
    max: `@media (max-width: ${breakpoints.l - 1}px)`,
  },
  l: {
    min: `@media (min-width: ${breakpoints.l}px)`,
    only: `@media (min-width: ${breakpoints.l}px) and (max-width: ${breakpoints.xl - 1}px)`,
    max: `@media (max-width: ${breakpoints.xl - 1}px)`,
  },
  xl: {
    min: `@media (min-width: ${breakpoints.xl}px)`,
    only: `@media (min-width: ${breakpoints.xl}px)`,
    max: `@media (min-width: 123345px)`, // shouldn't be used
  },
};
