import { SPLASH_SCREEN_ID } from 'shared/constants/app';

export const hideSplashScreen = () => {
  const splashScreen = document.getElementById(SPLASH_SCREEN_ID);

  if (!splashScreen) return;

  splashScreen.style.opacity = '0';
  setTimeout(() => {
    splashScreen.style.display = 'none';
  }, 200);
};
