import React from 'react';

import { StyledPlainButton, StyledSpinner, StyledRegularButton, StyledContent } from './styled';
import { ButtonProps } from './types';

const getButtonComponent = (variant: ButtonProps['variant']) => {
  if (variant === 'plain') return StyledPlainButton;

  return StyledRegularButton;
};

/**
 * Renders Button component that allows users to take actions, and make choices, with a single tap.
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      disabled = false,
      size = 'medium',
      type = 'button',
      variant = 'primary',
      uppercase = false,
      loading = false,
      pulsing = false,
      fullWidth = false,
      children,
      color,
      ...rest
    },
    ref
  ): JSX.Element => {
    const spinner = loading ? <StyledSpinner size={size} /> : null;
    const ButtonComponent = getButtonComponent(variant);

    return (
      <ButtonComponent
        ref={ref}
        $fullWidth={fullWidth}
        $loading={loading}
        $variant={variant}
        type={type}
        disabled={disabled}
        $size={size}
        $pulsing={pulsing}
        $color={color}
        {...rest}
      >
        <StyledContent $uppercase={uppercase} $loading={loading}>
          {children}
        </StyledContent>
        {spinner}
      </ButtonComponent>
    );
  }
);

export { Button };
