export const getQueryValueByKey = (key: string) => {
  const query = window?.location?.search;

  if (!query) {
    return '';
  }

  const urlSearchParams = new URLSearchParams(query);

  return urlSearchParams.get(key) || '';
};
