export const getForerunnerApiKey = (): string => {
  if (!import.meta.env.VITE_PUBLIC_FORERUNNER_API_KEY) {
    throw new Error('Forerunner api key is required');
  }

  return import.meta.env.VITE_PUBLIC_FORERUNNER_API_KEY || '';
};
export const getForerunnerApiUrl = () => {
  if (!import.meta.env.VITE_PUBLIC_FORERUNNER_API_URL) {
    throw new Error('Forerunner api url is required');
  }

  return import.meta.env.VITE_PUBLIC_FORERUNNER_API_URL;
};
