import React from 'react';
import styled, { keyframes } from 'styled-components';

import { ColorName } from 'ui-elements/themes/types';

const SIZE = 44;

type SpinnerSize = 'small' | 'medium' | 'large';

/**
 * Spinner component props
 */
export interface SpinnerProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * The thickness of line in Spinner component
   */
  thickness?: number;
  /**
   * The size of Spinner component: small or medium or large
   */
  size?: SpinnerSize;
  /**
   * The color of Spinner component
   * @default 'currentColor'
   */
  color?: 'currentColor' | ColorName;
}
const rotateAnimation = keyframes`
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
`;

const lineAnimation = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`;

interface StyledSpinnerProps {
  $size: Required<SpinnerProps>['size'];
  $color: Required<SpinnerProps>['color'];
}

const SIZES: Record<SpinnerSize, string> = {
  small: '24px',
  medium: '32px',
  large: '56px',
};

const StyledSpinner = styled.span<StyledSpinnerProps>`
  width: ${({ $size }) => SIZES[$size]};
  height: ${({ $size }) => SIZES[$size]};
  color: ${({ $color, theme }) => ($color === 'currentColor' ? $color : theme.color($color))};
  display: inline-block;
  padding: 4px;
  box-sizing: border-box;
`;

const CircularProgressSVG = styled.svg`
  display: block;
  animation: ${rotateAnimation} 1.4s ease-in-out infinite;
`;

const CircularProgressCircle = styled.circle`
  stroke: currentColor;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  animation: ${lineAnimation} 1.4s ease-in-out infinite;
`;

/**
 * Renders Spinner component.
 */
const Spinner = ({
  thickness = 4,
  size = 'medium',
  color = 'currentColor',
  ...rest
}: SpinnerProps) => {
  return (
    <StyledSpinner $size={size} $color={color} {...rest}>
      <CircularProgressSVG viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
        <CircularProgressCircle
          cx={SIZE}
          cy={SIZE}
          r={(SIZE - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
        />
      </CircularProgressSVG>
    </StyledSpinner>
  );
};

export { Spinner };
