import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { ColorName } from 'ui-elements/themes/types';

type Orientation = 'vertical' | 'horizontal';

interface DividerProps extends HTMLAttributes<HTMLHRElement> {
  orientation?: Orientation;
  color?: ColorName;
  width?: number;
}

const DividerWrapper = styled.hr<DividerProps>`
  border: 0;
  margin: 0;
  display: flex;

  ${({ theme, orientation, color, width = 1 }) => {
    return orientation === 'horizontal'
      ? `width: 100%; border-top: ${width}px solid ${theme.color(color || 'base-surface')}`
      : `height: 100%; border-left: ${width}px solid ${theme.color(color || 'base-surface')};`;
  }}
`;

export const Divider = ({ orientation = 'horizontal', color, ...rest }: DividerProps) => {
  return (
    <DividerWrapper
      {...rest}
      role="separator"
      aria-orientation={orientation === 'vertical' ? 'vertical' : 'horizontal'}
      orientation={orientation}
      color={color}
    />
  );
};
