import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const InputSkeleton = styled.div`
  height: 54px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color('border-primary-subdued')};
  box-shadow: ${({ theme }) => theme.shadow('sm')};

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #eee;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    background-size: 200px 100%;
    background-repeat: no-repeat;
    color: transparent;
    animation: ${loading} 1.5s infinite;
  }
`;

export const TextSkeleton = styled.span<{ $width: string; $color?: string }>`
  background-color: ${({ $color = '#eee' }) => $color};
  background-image: ${({ $color = '#eee' }) =>
    `linear-gradient(90deg, ${$color}, color-mix(in xyz, ${$color}, #fff), ${$color})`};
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: inherit;
  font: inherit;
  width: ${(props) => props.$width};
  color: transparent;
  border-radius: 4px;
  animation: ${loading} 1.5s infinite;
`;
