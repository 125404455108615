import React from 'react';

import { FormItem } from 'ui-elements/FormItem';
import { InputSkeleton, TextSkeleton } from 'ui-elements/Skeleton';
import { Spacer } from 'ui-elements/Spacer';

export const AccountProfileSkeleton = ({
  messages: { firstName, lastName, gender, age, units, currentWeight, targetWeight, height } = {},
}: {
  messages?: Record<string, string>;
}) => {
  return (
    <div>
      <div className="flex flex-col gap-4">
        <FormItem label={firstName ?? <TextSkeleton width="60px" />}>
          <InputSkeleton />
          <Spacer size={20} />
        </FormItem>

        <FormItem label={lastName ?? <TextSkeleton width="60px" />}>
          <InputSkeleton />
          <Spacer size={20} />
        </FormItem>

        <FormItem label={gender ?? <TextSkeleton width="60px" />}>
          <div className="mb-4 mt-4 flex flex-wrap items-center justify-between gap-4 pl-4">
            <TextSkeleton width="70px" className="h-[25px]" />
            <TextSkeleton width="70px" className="h-[25px]" />
            <TextSkeleton width="70px" className="h-[25px]" />
          </div>
        </FormItem>

        <FormItem label={age ?? <TextSkeleton width="60px" />}>
          <InputSkeleton />
          <Spacer size={20} />
        </FormItem>

        <FormItem
          label={units ?? <TextSkeleton width="60px" />}
          className="mb-4 mt-4 flex items-center justify-between"
        >
          <TextSkeleton width="120px" />
        </FormItem>

        <FormItem label={currentWeight ?? <TextSkeleton width="60px" />}>
          <InputSkeleton />
          <Spacer size={20} />
        </FormItem>

        <FormItem label={targetWeight ?? <TextSkeleton width="60px" />}>
          <InputSkeleton />
          <Spacer size={20} />
        </FormItem>

        <FormItem label={height ?? <TextSkeleton width="60px" />}>
          <InputSkeleton />
          <Spacer size={20} />
        </FormItem>
      </div>
    </div>
  );
};
