import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BurgerIcon } from 'shared/components/BurgerIcon/BurgerIcon';

import { useAuthUser } from 'app/providers/AuthUserProvider';
import { IconButton } from 'ui-elements/IconButton';
import { IconAppLogo } from 'ui-elements/Icons/IconAppLogo';
import { IconUser } from 'ui-elements/Icons/IconUser';

import { ROUTES } from 'shared/constants/routes';

export const Header = ({
  onToggleMenu,
  isMenuVisible,
}: {
  onToggleMenu: () => void;
  isMenuVisible: boolean;
}) => {
  const {
    models: { authenticated },
  } = useAuthUser();
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate(ROUTES.ACCOUNT_PROFILE);
  };

  return (
    <header className="sticky top-0 z-10 flex h-14 items-center justify-between bg-surface-default px-4 py-0">
      {authenticated && (
        <IconButton onClick={onToggleMenu} className="flex items-center xl:!hidden">
          <BurgerIcon isOpen={isMenuVisible} />
        </IconButton>
      )}
      <IconAppLogo />
      {authenticated && (
        <IconUser className="cursor-pointer" width={24} height={24} onClick={goToProfile} />
      )}
    </header>
  );
};
