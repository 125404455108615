import { StorageValue } from 'shared/utils/storage';

import { isLevel } from './helpers';
import { Logger } from './logger';
import * as transports from './transports';

const levelValue = new StorageValue({ key: 'frr:log_level' });

const IS_DEVTOOLS_AVAILABLE = false;

export const logger = new Logger({
  transports: [
    transports.console,
    transports.sentry,
    ...(IS_DEVTOOLS_AVAILABLE ? [transports.devtools] : []),
  ],
});

// save logger level to storage
logger.events.onLevelChange.on((value) => {
  levelValue.set(value);
});

export const initLogger = () => {
  const initialLevel = process.env.APP_ENV === 'prod' ? 'error' : 'info';

  // eslint-disable-next-line no-underscore-dangle
  window.__setAppLogLevel__ = (value?: unknown) => {
    if (isLevel(value)) {
      logger.level = value;
    }
  };

  const levelFromStorage = levelValue.get();

  logger.level = isLevel(levelFromStorage) ? levelFromStorage : initialLevel;
};

initLogger();
