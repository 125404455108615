import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from 'app';

import { initSentry } from 'shared/services/Sentry';
import { analyticService } from 'shared/services/analytics';

import 'styles/colors/default.css';
import 'styles/fonts.css';
import 'styles/global.css';
import 'styles/themes/default.css';
import 'styles/typography.css';
import 'styles/tailwind.css';

initSentry();
analyticService.initAnalytics();

window.APP_VERSION = process.env.APP_VERSION;
window.APP_BUILD_DATE = process.env.APP_BUILD_DATE;

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
