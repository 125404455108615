import React from 'react';
import styled from 'styled-components';

/**
 * Spacer component props
 */
interface SpacerProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Axis
   * @default 'vertical'
   */
  axis?: 'vertical' | 'horizontal';
  /**
   * Size in pixels
   * @default 16
   */
  size?: number;
}

interface StyledSpacerProps {
  $axis: Required<SpacerProps>['axis'];
  $size: Required<SpacerProps>['size'];
}

const getHeight = ({ $axis, $size }: StyledSpacerProps) => {
  return $axis === 'horizontal' ? 1 : $size;
};

const getWidth = ({ $axis, $size }: StyledSpacerProps) => {
  return $axis === 'vertical' ? 1 : $size;
};

const StyledSpacer = styled.span<StyledSpacerProps>`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
`;

/**
 * Renders Spacer component
 */
const Spacer = ({ size = 16, axis = 'vertical', ...rest }: SpacerProps) => {
  return <StyledSpacer $size={size} $axis={axis} {...rest} />;
};

export { Spacer };
