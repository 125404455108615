// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react';

const IconUser = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle cx={12} cy={12} r={12} fill="#F2EFEE" />
      <mask
        id="a"
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
      >
        <circle cx={12} cy={12} r={12} fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#D6D7DB"
          fillRule="evenodd"
          d="M9.827 15.252a3.7 3.7 0 0 1-.596-.59 4.56 4.56 0 0 1-.846-2.243.4.4 0 0 1-.105.014c-.41 0-.722-1.001-.722-1.705s.211-.918.412-.918q.08 0 .16.011a7 7 0 0 1-.193-1.52c0-2.632 1.009-4.136 4.162-4.136 1.502 0 1.604.392 1.892.94a.88.88 0 0 1 .568-.188c.802 0 1.703 1.023 1.703 3.385a7 7 0 0 1-.193 1.52q.08-.012.159-.012c.201 0 .412.214.412.918s-.31 1.705-.721 1.705a.4.4 0 0 1-.105-.014 4.56 4.56 0 0 1-.847 2.244 3.7 3.7 0 0 1-.596.59c.03 1.965.567 2.684 2.803 3.415 2.092.685 3.079 1.163 3.253 2.922a.57.57 0 0 1-.568.625s-5.074 3.57-7.76 3.57c-2.487 0-7.76-3.57-7.76-3.57a.57.57 0 0 1-.568-.625c.175-1.76 1.161-2.237 3.253-2.922 2.236-.731 2.774-1.45 2.803-3.416"
          clipRule="evenodd"
        />
      </g>
    </svg>
  );
};
export { IconUser };
