export class Cache {
  private readonly cache: Record<string, unknown | undefined>;

  constructor() {
    this.cache = {};
  }

  set<TData>(key: string, value: TData) {
    this.cache[key] = value;
  }

  get<TData>(key: string): TData | undefined {
    return this.cache[key] as TData;
  }
}
