import React from 'react';

import { Image } from 'shared/components/Image';

import { Button } from 'ui-elements/Button';
import { Divider } from 'ui-elements/Divider';
import { TextSkeleton } from 'ui-elements/Skeleton';
import { Stack } from 'ui-elements/Stack';
import { Typography } from 'ui-elements/Typography';

import { getColorValue } from 'shared/utils/color';

import { Row } from './Row';

import { config } from '../config';

export const SubscriptionCardSkeleton = () => {
  const skeletonColor = getColorValue('red100');
  return (
    <div className="flex flex-col items-stretch gap-2 rounded-lg border-2 border-solid border-red-100 bg-red-0 p-4">
      <Stack direction="row" justifyContent="flex-start" gap={8}>
        <Image
          sources={config.images.appLogo.sources}
          src={config.images.appLogo.src}
          alt="app logo"
          width={30}
          height={30}
        />
        <Typography variant="heading3">
          <TextSkeleton width="120px" color={skeletonColor} />
        </Typography>
      </Stack>
      <Divider width={1} className="!mb-4 !mt-1" color="red100" />

      <TextSkeleton width="75px" className="h-7" color={skeletonColor} />

      <Typography variant="heading3">
        <TextSkeleton width="100px" color={skeletonColor} />
      </Typography>

      <Row
        leftText={<TextSkeleton width="60px" color={skeletonColor} />}
        rightText={<TextSkeleton width="120px" color={skeletonColor} />}
      />
      <Row
        leftText={<TextSkeleton width="70px" color={skeletonColor} />}
        rightText={<TextSkeleton width="120px" color={skeletonColor} />}
      />

      <Divider width={1} className="!mb-4 !mt-4" color="red100" />

      <TextSkeleton width="120px" className="h-7" color={skeletonColor} />

      <Typography variant="heading3">
        <TextSkeleton width="100px" color={skeletonColor} />
      </Typography>

      <Row
        leftText={<TextSkeleton color={skeletonColor} width="70px" />}
        rightText={<TextSkeleton color={skeletonColor} width="120px" />}
      />
      <Row
        leftText={<TextSkeleton color={skeletonColor} width="80px" />}
        rightText={<TextSkeleton color={skeletonColor} width="120px" />}
      />
      <Row
        leftText={<TextSkeleton color={skeletonColor} width="120px" />}
        rightText={<TextSkeleton color={skeletonColor} width="120px" />}
      />

      <Divider width={1} className="!mb-4 !mt-4" color="red100" />

      <Button variant="plain" size="small" className="self-start">
        <TextSkeleton color={skeletonColor} width="120px" />
      </Button>
    </div>
  );
};
