import cx from 'classnames';
import React, { HTMLProps, PropsWithChildren } from 'react';

type OverlayProps = PropsWithChildren<HTMLProps<HTMLDivElement> & { visible?: boolean }>;

export const Overlay = ({ visible, ...rest }: OverlayProps) => (
  <div
    className={cx(
      'fixed left-0 z-10 h-full w-full bg-black-80',
      'pointer-events-none opacity-0 transition-[opacity] duration-300',
      visible && 'pointer-events-auto opacity-100',
      'xl:pointer-events-none xl:opacity-0'
    )}
    {...rest}
  />
);
