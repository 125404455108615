import Cookies from 'js-cookie';

import { logger } from 'shared/services/logger';
import { getQueryValueByKey } from 'shared/utils/common';

import { LocaleSource } from './constants';
import { LookupOptions } from './types';

export function cacheLocaleInCookie(locale: string, options: LookupOptions) {
  Cookies.set(options.cookie, locale, {
    expires: 365,
    sameSite: 'Lax',
    secure: true,
    path: '/',
  });
}

export function cacheLocaleInLocalStorage(locale: string, options: LookupOptions) {
  const localStorage = window?.localStorage;

  if (localStorage) {
    try {
      localStorage.setItem(options.localStorage, locale);
    } catch (err) {
      logger.error(Error('localStorage is not writable', { cause: err }));
    }
  }
}

export function lookupLocaleFromCookie(supportedLocales: Array<string>, options: LookupOptions) {
  const locale = Cookies.get(options[LocaleSource.COOKIE]) || '';

  if (supportedLocales.includes(locale)) {
    return locale;
  }

  return '';
}

export function lookupLocaleFromLocalStorage(
  supportedLocales: Array<string>,
  options: LookupOptions
) {
  const localStorage = window?.localStorage;

  if (localStorage) {
    const locale = localStorage.getItem(options[LocaleSource.LOCAL_STORAGE]) || '';

    if (supportedLocales.includes(locale)) {
      return locale;
    }
  }

  return '';
}

export function lookupLocaleFromQuery(supportedLocales: Array<string>, options: LookupOptions) {
  const locale = getQueryValueByKey(options[LocaleSource.QUERY]);

  return supportedLocales.includes(locale) ? locale : '';
}

export function lookupLocaleFromPath(supportedLocales: Array<string>) {
  const pathname = window?.location?.pathname;

  if (pathname) {
    const pathnameParts = pathname.match(/\/([a-zA-Z-]*)/g);

    if (Array.isArray(pathnameParts)) {
      const locale = pathnameParts[0].replace('/', '');

      if (supportedLocales.includes(locale)) {
        return locale;
      }
    }
  }

  return '';
}

export function lookupLocaleFromBrowser(supportedLocales: Array<string>) {
  const [locale] = window?.navigator?.language?.split('-') || [];

  if (supportedLocales.includes(locale)) {
    return locale;
  }

  return '';
}
