import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';

import { isExceptionLogFnParams } from '../helpers';
import { Level, TransportFn } from '../types';

type Primitive = number | string | boolean | bigint | symbol | null | undefined;

export const LOG_LEVEL_TO_SEVERITY_MAP: Record<Level, Sentry.SeverityLevel | undefined> = {
  silent: undefined,
  fatal: 'fatal',
  error: 'error',
  warn: 'warning',
  info: 'info',
  debug: 'debug',
  trace: 'debug',
};

const EVENTS_TO_SEND: string[] = [];

const getTagsFromParams = (paramsList: unknown[]): Record<string, Primitive> => {
  for (const param of paramsList) {
    if (param && typeof param === 'object') {
      const { tags } = param as { tags: unknown };

      if (tags && typeof tags === 'object') {
        return tags as Record<string, Primitive>;
      }
    }
  }

  return {};
};

const getAxiosError = (error: Error): AxiosError | null => {
  if (axios.isAxiosError(error)) {
    return error;
  }

  if (axios.isAxiosError(error.cause)) {
    return error.cause;
  }

  return null;
};

const getTagsByAxiosError = (error: Error): Record<string, Primitive> => {
  const axiosError = getAxiosError(error);

  if (axiosError) {
    return {
      'api.status': axiosError.response?.status || 999,
      'api.code': axiosError.code,
      'api.url': axiosError.config?.url,
      'api.base_url': axiosError.config?.baseURL,
    };
  }

  return {};
};

const captureEvent = ({
  eventName,
  methodFnArgs,
}: {
  eventName: string;
  methodFnArgs: unknown[];
}) => {
  const tags = {
    // add tags from logger arguments
    ...getTagsFromParams(methodFnArgs),
  };

  Sentry.withScope((scope) => {
    try {
      for (const tagName of Object.keys(tags)) {
        scope.setTag(tagName, tags[tagName]);
      }

      Sentry.captureException(new Error(eventName));
    } catch (err) {
      Sentry.captureException(err);
      Sentry.captureMessage('transports/sentry: catch block');
    }
  });
};

const captureException = ({ error, methodFnArgs }: { error: Error; methodFnArgs: unknown[] }) => {
  const tags = {
    // add tags from logger arguments
    ...getTagsFromParams(methodFnArgs),
    // add common tags for all axios request errors
    ...getTagsByAxiosError(error),
  };

  Sentry.withScope((scope) => {
    try {
      for (const tagName of Object.keys(tags)) {
        scope.setTag(tagName, tags[tagName]);
      }

      Sentry.captureException(error || new Error(methodFnArgs.join(', ')));
    } catch (err) {
      Sentry.captureException(err);
      Sentry.captureMessage('transports/sentry: catch block 2');
    }
  });
};

export const transport: TransportFn = (params) => {
  const { level, levelsConfig, methodFnArgs, isLevelEnabled } = params;
  const { value: levelValue } = levelsConfig[level];

  const eventName = typeof methodFnArgs?.[0] === 'string' ? methodFnArgs[0] : null;

  // capture all logs included in EVENTS_TO_SEND as exceptions by sentry
  if (eventName && EVENTS_TO_SEND.includes(eventName)) {
    captureEvent({ eventName, methodFnArgs });
    return;
  }

  // capture all logs above 'warn' level as exceptions by sentry
  if (levelValue > levelsConfig.warn.value && isExceptionLogFnParams(params)) {
    captureException({ error: params.error, methodFnArgs });
    return;
  }

  // add logs for disabled levels and below to sentry breadcrumbs
  if (!isLevelEnabled) {
    Sentry.addBreadcrumb({
      type: 'debug',
      category: 'console',
      message: methodFnArgs.join(', '),
      level: LOG_LEVEL_TO_SEVERITY_MAP[level],
      data: { arguments: methodFnArgs, logger: 'console' },
    });
  }
};
