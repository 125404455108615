import React, { Suspense, Fragment, ReactNode, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ErrorBoundary } from 'shared/components/ErrorBoundary';
import { PrivateRoute } from 'shared/components/PrivateRoute/PrivateRoute';

import { useLocalization } from 'app/providers/LocalizationProvider';
import { NotFound } from 'pages/404-not-found/NotFound';

import { useDocumentSocialWebViewBrowser } from 'shared/hooks/useDocumentOperations';

import { RouteConfig, ROUTES_CONFIG } from 'shared/constants/routeConfig';
import { useAnalytic } from 'shared/services/analytics/hooks/useAnalytic';
import { hideSplashScreen } from 'shared/utils/splashScreen';

import { RootLayout } from './RootLayout';
import { withProviders } from './providers/withProviders';

const AppComponent = () => {
  useDocumentSocialWebViewBrowser();
  useAnalytic();

  const {
    models: { isReady },
  } = useLocalization();

  useEffect(() => {
    if (isReady) {
      hideSplashScreen();
    }
  }, [isReady]);

  return (
    <ErrorBoundary>
      <RootLayout>
        <Suspense fallback={null}>
          <Routes>
            {ROUTES_CONFIG.map(({ children, ...routeConfig }) => {
              const childrenMapped = children?.map((childRoute) => renderRoute(childRoute));

              return renderRoute(routeConfig, childrenMapped);
            })}

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </RootLayout>
    </ErrorBoundary>
  );
};

const renderRoute = (
  { Component, url, isPrivate, fallback }: Omit<RouteConfig, 'children'>,
  children?: ReactNode[]
) => {
  const PrivateRouteWrapper = isPrivate ? PrivateRoute : Fragment;

  return (
    <Route
      key={url}
      path={url}
      element={
        <Suspense fallback={fallback}>
          <PrivateRouteWrapper>
            <Component />
          </PrivateRouteWrapper>
        </Suspense>
      }
    >
      {children}
    </Route>
  );
};

export const App = withProviders(AppComponent);
