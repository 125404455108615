import { Level, LevelsConfig } from './types';

export const LOG_LEVELS: Array<Level> = [
  'fatal',
  'error',
  'warn',
  'info',
  'debug',
  'trace',
  'silent',
];

export const LEVELS_CONFIG: LevelsConfig = {
  silent: {
    value: Infinity,
  },
  fatal: {
    value: 60,
  },
  error: {
    value: 50,
  },
  warn: {
    value: 40,
  },
  info: {
    value: 30,
  },
  debug: {
    value: 20,
  },
  trace: {
    value: 10,
  },
};
