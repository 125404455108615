import { useEffect } from 'react';

import { AuthStatus, useAuthUser } from 'app/providers/AuthUserProvider';

import { analyticService } from '../service';
import { userReadyService } from '../userReadyService';

export const useAnalytic = () => {
  const {
    models: { user, authStatus },
  } = useAuthUser();

  useEffect(() => {
    if (user?.gender) {
      localStorage.setItem('gender', user?.gender);
    }
    if (user?.country) {
      localStorage.setItem('country', user?.country);
    }
  }, [user?.country, user?.gender]);

  useEffect(() => {
    if (!user && authStatus === AuthStatus.DONE) {
      userReadyService.resolve(null);
    }
  }, [authStatus, user]);

  useEffect(() => {
    if (!user) return;

    analyticService.setUserData(user).then(() => {
      userReadyService.resolve(null);
    });
  }, [user]);
};
