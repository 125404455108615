import styled from 'styled-components';

/**
 * BaseButton component that contains common styles
 */
export const BaseButton = styled.button<{ fullWidth?: boolean }>`
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  width: ${({ fullWidth }) => (fullWidth ? '100% !important' : '')};
  box-sizing: border-box;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  color: inherit;

  &::-moz-focus-inner {
    border-style: none;
  }

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }
`;
