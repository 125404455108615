import { Component, PropsWithChildren, ReactNode } from 'react';

import { logger } from 'shared/services/logger';

type Props = PropsWithChildren<{
  fallback?: ReactNode;
}>;

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: { componentStack: any }) {
    logger.error(new Error('Root ErrorBoundary'), {
      cause: error,
      originalStack: info.componentStack,
    });
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;

    if (hasError) {
      return fallback ?? null;
    }

    return children;
  }
}
