import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  app: {
    id: 'yMufdX',
    defaultMessage: 'Mindea APP',
    description: 'Navigation: app information tab: title',
  },
  accountSettings: {
    id: 'kbPAZ6',
    defaultMessage: 'Account Settings',
    description: 'Navigation: account settings tab: title',
  },
  signout: {
    id: 'g9UiSC',
    defaultMessage: 'Log Out',
    description: 'Navigation: log out button',
  },
});
