export enum ActionEventAction {
  load = 'load',
  login = 'login',
  resetPassword = 'reset_password',
  downloadApp = 'download_app',
  save = 'save',
  cancelSubscription = 'cancel_subscription',
  logOut = 'log_out',
}

export enum ElementEventAction {
  screen = 'screen',
  button = 'button',
}
