import React from 'react';
import styled from 'styled-components';

/**
 * StepLayout component props
 */

const StyledStepLayout = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.m.min} {
    margin: 0 auto;
    padding-bottom: 48px;
  }
`;

type StyledProps = Parameters<typeof StyledStepLayout>[0] & React.HTMLAttributes<HTMLElement>;

export interface StepLayoutProps extends StyledProps {}

/**
 * Renders onboarding screen step layout
 */
const StepLayout = React.forwardRef<HTMLDivElement, StepLayoutProps>((props, ref) => {
  return <StyledStepLayout ref={ref} {...props} />;
});

export { StepLayout };
