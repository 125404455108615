import cx from 'classnames';
import React from 'react';

/**
 * StepContent component props
 */
export interface StepContentProps extends React.HTMLAttributes<HTMLElement> {}

/**
 * Renders onboarding screen step content layout
 */
const StepContent = React.forwardRef<HTMLDivElement, StepContentProps>(
  ({ className, ...props }: StepContentProps, ref) => {
    return (
      <div
        ref={ref}
        className={cx(
          'mx-auto my-0 box-border w-full max-w-[608px] shrink-0 grow basis-auto px-4 pb-6 pt-0',
          'md:pb-0, md:grow-0',
          'lg:px-0 lg:pb-6 lg:pt-0',
          className
        )}
        {...props}
      />
    );
  }
);

export { StepContent };
