/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import app_logo_2x_avif from './app-logo-2x.avif';
import app_logo_1x_avif from './app-logo-1x.avif';
import app_logo_2x_webp from './app-logo-2x.webp';
import app_logo_1x_webp from './app-logo-1x.webp';
import app_logo_2x_png from './app-logo-2x.png';
import app_logo_1x_png from './app-logo-1x.png';

import { preloadImages } from 'shared/utils/image';

export const config = [
  {
    media: '',
    srcSet: `${app_logo_2x_avif} 2x, ${app_logo_1x_avif} 1x`,
    type: 'image/avif',
  },
  {
    media: '',
    srcSet: `${app_logo_2x_webp} 2x, ${app_logo_1x_webp} 1x`,
    type: 'image/webp',
  },
  {
    media: '',
    srcSet: `${app_logo_2x_png} 2x, ${app_logo_1x_png} 1x`,
    type: 'image/png',
  },
];

export const fallbackImageUrl = app_logo_2x_png;

preloadImages(config);
