import cx from 'classnames';
import React from 'react';

import css from './BurgerIcon.module.css';

export const BurgerIcon = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <div className={cx(css['icon-container'], isOpen && css.open)}>
      <div className={cx(css.line)} />
      <div className={cx(css.line)} />
      <div className={cx(css.line)} />
      <div className={cx(css.line)} />
    </div>
  );
};
